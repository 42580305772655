<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotResource' }">组态资源</el-breadcrumb-item>
          <el-breadcrumb-item>新增资源</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div class="form-title" style="display:flex;justify-content:space-between;align-items:center;">
            <div>上传资源</div>
            <div style="display:flex;">
              <el-button type="danger" size="mini" :disabled="allFileList.length==0" @click="allDel">全部删除</el-button>
              <el-upload
                action="#"
                multiple
                list-type="picture-card"
                :file-list="allFileList"
                :on-change="uploadChange"
                :show-file-list="false"
                :auto-upload="false">
                <el-button size="mini" type="primary">上传资源</el-button>
              </el-upload>
            </div>
          </div>
          <div class="resources" v-if="allFileList.length>0">
            <div class="resource-wrap" v-for="(item, index) in allFileList" :key="index">
              <div class="resource-img" :style="item.resourceName?'border-color:rgb(46 213 115 / 20%);background:rgb(46 213 115 / 6%);':''">
                <el-image class="imgs" :src="item.url" fit="contain"></el-image>
                <div class="r-mask">
                  <i class="el-icon-search" @click="findImg(item)"></i>
                  <i class="el-icon-delete" @click="delImg(index)"></i>
                </div>
              </div>
              <div class="resource-name">
                <i :style="item.resourceName?'color:#2ed573;':'color:#ff4757;'">*</i>
                <el-input v-model="item.resourceName" size="small" placeholder="请输入图片别名"></el-input>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="resource-empty">
              <div class="empty-icon">
                <svg viewBox="0 0 79 86" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <linearGradient id="linearGradient-1-4" x1="38.8503086%" y1="0%" x2="61.1496914%" y2="100%">
                      <stop stop-color="#FCFCFD" offset="0%"></stop>
                      <stop stop-color="#EEEFF3" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient id="linearGradient-2-4" x1="0%" y1="9.5%" x2="100%" y2="90.5%">
                      <stop stop-color="#FCFCFD" offset="0%"></stop>
                      <stop stop-color="#E9EBEF" offset="100%"></stop>
                    </linearGradient>
                    <rect id="path-3-4" x="0" y="0" width="17" height="36"></rect>
                  </defs>
                  <g id="Illustrations" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="B-type" transform="translate(-1268.000000, -535.000000)">
                      <g id="Group-2" transform="translate(1268.000000, 535.000000)">
                        <path id="Oval-Copy-2" d="M39.5,86 C61.3152476,86 79,83.9106622 79,81.3333333 C79,78.7560045 57.3152476,78 35.5,78 C13.6847524,78 0,78.7560045 0,81.3333333 C0,83.9106622 17.6847524,86 39.5,86 Z" fill="#F7F8FC"></path>
                        <polygon id="Rectangle-Copy-14" fill="#E5E7E9" transform="translate(27.500000, 51.500000) scale(1, -1) translate(-27.500000, -51.500000) " points="13 58 53 58 42 45 2 45"></polygon>
                        <g id="Group-Copy" transform="translate(34.500000, 31.500000) scale(-1, 1) rotate(-25.000000) translate(-34.500000, -31.500000) translate(7.000000, 10.000000)">
                          <polygon id="Rectangle-Copy-10" fill="#E5E7E9" transform="translate(11.500000, 5.000000) scale(1, -1) translate(-11.500000, -5.000000) " points="2.84078316e-14 3 18 3 23 7 5 7"></polygon>
                          <polygon id="Rectangle-Copy-11" fill="#EDEEF2" points="-3.69149156e-15 7 38 7 38 43 -3.69149156e-15 43"></polygon>
                          <rect id="Rectangle-Copy-12" fill="url(#linearGradient-1-4)" transform="translate(46.500000, 25.000000) scale(-1, 1) translate(-46.500000, -25.000000) " x="38" y="7" width="17" height="36"></rect>
                          <polygon id="Rectangle-Copy-13" fill="#F8F9FB" transform="translate(39.500000, 3.500000) scale(-1, 1) translate(-39.500000, -3.500000) " points="24 7 41 7 55 -3.63806207e-12 38 -3.63806207e-12"></polygon>
                        </g>
                        <rect id="Rectangle-Copy-15" fill="url(#linearGradient-2-4)" x="13" y="45" width="40" height="36"></rect>
                        <g id="Rectangle-Copy-17" transform="translate(53.000000, 45.000000)">
                          <mask id="mask-4-4" fill="white">
                            <use xlink:href="#path-3-4"></use>
                          </mask>
                          <use id="Mask" fill="#E0E3E9" transform="translate(8.500000, 18.000000) scale(-1, 1) translate(-8.500000, -18.000000) " xlink:href="#path-3-4"></use>
                          <polygon id="Rectangle-Copy" fill="#D5D7DE" mask="url(#mask-4-4)" transform="translate(12.000000, 9.000000) scale(-1, 1) translate(-12.000000, -9.000000) " points="7 0 24 0 20 18 -1.70530257e-13 16"></polygon>
                        </g>
                        <polygon id="Rectangle-Copy-18" fill="#F8F9FB" transform="translate(66.000000, 51.500000) scale(-1, 1) translate(-66.000000, -51.500000) " points="62 45 79 45 70 58 53 58"></polygon>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <p>暂无资源</p>
            </div>
          </div>
          
          <!-- <div class="prompt">注意：图片尺寸800*800px，大小≤2MB，支持JPG、PNG、JPEG，最多上传5张</div> -->
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/iotResource')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
      <el-dialog
        title="查看资源"
        :visible.sync="dialogVisible"
        width="50%"
        height="60%">
        <el-image class="view-large-image" :src="showImage" fit="contain"></el-image>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
      },
      disabled: false,
      dialogVisible: false,
      showImage: null,
      allFileList: [],
    };
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let formData = new FormData();
          let ins = this.allFileList.every(v => v.resourceName )
          if(this.allFileList.length==0) {
            this.$message.error('请上传资源')
            return
          }
          if(!ins) {
            this.$message.error('请输入图片别名')
            return
          }
          this.allFileList.forEach((item,index) => {
            formData.append('resources['+index+"].resourceFile", item.raw);
            formData.append('resources['+index+"].resourceName", item.resourceName);
          });
          // for(let [v,k] of formData) {
          //   console.log(v+'---'+k)
          // }
          this.loading = true;
          this.$ajax.post("resourceCreate", formData).then((res) => {
            this.loading = false;
            this.$message.success("成功");
            this.$router.push("/iotResource");
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    uploadChange(file, fileList) {
      this.allFileList = fileList;
      console.log(this.allFileList);
    },
    handleRemove(file) {
      function checkAdult(item) {
        return item.uid != file.uid;
      }
      this.allFileList = this.allFileList.filter(checkAdult);

      console.log(this.allFileList);
    },
    // 删除所有资源
    allDel() {
      this.allFileList = []
    },
    // 查看大图
    findImg(item) {
      this.dialogVisible = true
      this.showImage = item.url
    },
    // 删除指定资源
    delImg(index) {
      this.allFileList.splice(index,1)
    }
  },
  mounted() {

  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 1200px;
}
.add-checkContent {
  font-size: 14px;
  color: #66b1ff;
  line-height: 50px;
  padding-left: 28px;
  span {
    cursor: pointer;
  }
}
.el-icon-close {
  color: #1494fb;
  margin-left: 20px;
  cursor: pointer;
}
.prompt {
  font-size: 14px;
  color: #9e9e9e;
  margin-top: 10px;
}
.resources {
  display: flex;
  flex-wrap: wrap;
}
.resource-wrap {
  width: 200px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resource-img {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
  border: 1px solid #efefef;
  user-select: none;
  transition: all .3s ease;
  .imgs {
    width: 100%;
    height: 100%;
  }
}
.resource-img:hover .r-mask{
  display: block;
  transition: all .3s ease;
}
.resource-name {
  margin-top: 10px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  user-select: none;
  i {
    color: red;
    font-size: 24px;
    margin-top: 0px;
    margin-right: 5px;
  }
}
.r-mask {
  width: 170px;
  height: 170px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  transition: all .3s ease;
  display: none;
  text-align: center;
  line-height: 170px;
  i {
    color: #FFF;
    font-size: 24px;
    cursor: pointer;
    margin: 0 10px;
  }
}
.resource-empty {
  width: 100%;
  border: 1px solid #efefef;
  background: #efefef42;
  border-radius: 10px;
  padding: 150px 0;
  .empty-icon {
    width: 200px;
    margin: 0 auto;
  }
  p {
    text-align: center;
    font-size: 14px;
    color: #bababa;
    user-select: none;
  }
}
.view-large-image {
  width: 500px;
  height: 500px;
  user-select: none;
}
/deep/.el-dialog__body {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.el-upload--picture-card {
  background-color: #fff;
  border: none;
  width: 90px;
  height: 18px;
  line-height: 20px;
}
</style>